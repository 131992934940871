import abi from "./contract/GlobalPool.json";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import Buy from "./component/buy";
import Memos from "./component/memos";
import "./copy.png";
import Buy3 from "./component/buy3";
import './assets/css/style.css';
import './assets/css/bootstrap.css';


function App() {
  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[level,setLevel]=useState("None");
  const[wlevel,setLevelw]=useState("None");

  const[wlevel1,setLevelw1]=useState("None");
  const[plevel,setLevelp]=useState("None");
  const[pack,setpackage]=useState("None");
  const[pack3,setpackage3]=useState("None");
  const[pack1,setpackage1]=useState("None");
  const[refer,setrefer]=useState("None");
  const[refer4,setrefer4]=useState("None");
  const[refer3,setrefer3]=useState("None");
  const[refer1,setrefer1]=useState("None");
  const[refer2,setrefer2]=useState("None");
const [tasks,setTasks] = useState([]);
const [tasks1,setTasks1] = useState([]);
const [tt,settt]=useState("None");
const [tt4,settt4]=useState("None");
const[dd,setDD]=useState("None");
const[dd1,setDD1]=useState("None");
const[dd2,setDD2]=useState("None");
const[referlink,setreflink]=useState("None");
const[bonanza,setBonanza]=useState("None");
const[upd,setupd]=useState("None");
const [tt1,settt1]=useState("None");
const [roi,seroi]=useState("None");
const [roi1,seroi1]=useState("None");
const [roi2,seroi2]=useState("None");
const [a1,sea1]=useState("None");
const [a2,sea2]=useState("None");
const [a3,sea3]=useState("None");
const [tt2,settt2]=useState("None");
const [tt3,settt3]=useState("None");
const[b1,setb1]=useState("None");
const[b2,setb2]=useState("None");
const[b3,setb3]=useState("None");
const[b4,setb4]=useState("None");
const[b5,setb5]=useState("None");
const[b6,setb6]=useState("None");
const[b7,setb7]=useState("None");
const[b8,setb8]=useState("None");
const[b9,setb9]=useState("None");
const[b10,setb10]=useState("None");
const[b11,setb11]=useState("None");
const[b12,setb12]=useState("None");
const[b13,setb13]=useState("None");
const[b14,setb14]=useState("None");
const[b15,setb15]=useState("None");
const[b16,setb16]=useState("None");
const[reward,setreward]=useState("None");
const[reward1,setreward1]=useState("None");
const[reward2,setreward2]=useState("None");
const[reward3,setreward3]=useState("None");
const[x1,setx1]=useState("None");
const[x2,setx2]=useState("None");
const[x3,setx3]=useState("None");

  useEffect(() => {
    
    const connectWallet = async () => {
      
      const contractAddress = "0x2a33cad835512694ea5774222abf044c85a585d6";
      const contractABI = abi.abi;
      const searchParams = new URLSearchParams(window.location.search);
    const myParam = searchParams.get('ref');
    console.log(myParam);
    document.querySelector("#name").value=myParam;
if(myParam == null)
  {
    document.querySelector("#name").value="0xC4867c60D7fC28Ec05Ae8f94Bf14905f117EdB9d";
  }
  
  try {
    const { ethereum } = window;
  //  document.querySelector("#test").value = "0";
    if (ethereum) {
      const account = await ethereum.request({
        method: "eth_requestAccounts",
       
      });
     
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });

      window.ethereum.on("accountsChanged", () => {
        
        window.location.reload();
      });
          const provider = new ethers.providers.Web3Provider(ethereum);
        
          try {
            const selectedNetwork = await provider.getNetwork();
          
            if (selectedNetwork.chainId !== 56) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
              });
              console.log("You have switched to the Binance network");
            } else {
              console.log("You are already on the Binance network");
            }
          } catch (switchError) {
            // The network has not been added to MetaMask
            if (switchError.code === 4902) {
              console.log("Please add the Binance network to MetaMask");
            } else {
              console.log("Cannot switch to the network");
            }
          }
        
        
        
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );
          setAccount(account);
          setState({ provider, signer, contract });
          document.querySelector("#us").value  = account[0];
      let alltasks = await contract.getMemos(account[0]);
      setTasks(alltasks);
	  let alltasks1 = await contract.getMemos1(account[0]);
      setTasks1(alltasks1);
      var i = 0;
      const refer = (alltasks[1]);
      setrefer(refer);
      const a1 = Number(alltasks[0]);
      sea1(a1);
      const refer2 = (alltasks[23]);
     setrefer2(refer2);
 
      const b =Number(alltasks[2]);
     

     document.querySelector("#hid").value  = (alltasks[1]);
     const direct =Number(alltasks[2]);
     setDirect(direct);
     const level = Number(alltasks[4]);
     setLevel(level);


     const plevel = Number(alltasks[21])/1000000000000000000;
     setLevelp(plevel);
     
    
     const pack = Number(alltasks[3]) ;

setpackage(pack);

document.querySelector("#pp").value  = Number(alltasks[3]);




     setB(b);

     

     const dd = Number(alltasks[9]);
          
     setDD(dd);

     const reward = Number(alltasks[15])/1000000000000000000;
          
     setreward(reward);
     const reward1 = Number(alltasks[21])/1000000000000000000;
          
     setreward1(reward1);
     const reward2 = Number(alltasks[22])/1000000000000000000;
          
     setreward2(reward2);
     const reward3 = Number(alltasks[23])/1000000000000000000;
          
     setreward3(reward3);



     const x1 = Number(alltasks[24])/1000000000000000000;
     setx1(x1);
     const x2 = Number(alltasks[25])/1000000000000000000;
     setx2(x2);
     const x3 = Number(alltasks[26])/1000000000000000000;
     setx3(x3);
     


     const dd2 = Number(alltasks[9]);
          
     setDD2(dd2);

     const dd1 =  Date.now() / 1000;
     setDD1(dd1);


     const tt1 = Math.trunc((dd1 - dd) / (3660 * 24 )) ;
   //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
     
     settt1(tt1);

     const tt2 = Math.trunc((dd1 - dd2) / (3600 * 24  )) ;
     //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
       
       settt2(tt2);
      
       document.querySelector("#tt2").value = tt2;
       
       const b1 = Number(alltasks[5]) ;
       setb1(b1);
       const b2 = Number(alltasks[6]) ;
       setb2(b2);
       const b3 = Number(alltasks[7]) ;
       setb3(b3);
       const b4 = Number(alltasks[8]) ;
       setb4(b4);
       const b5 = Number(alltasks[9]) ;
       setb5(b5);
       const b6 = Number(alltasks[10]) ;
       setb6(b6);
       const b7 = Number(alltasks[11]) ;
       setb7(b7);
       const b8 = Number(alltasks[12]) ;
       setb8(b8);
       const b9 = Number(alltasks[13]) ;
       setb9(b9);
       const b10 = Number(alltasks[0]) ;
       setb10(b10);


     const wlevel = Number(alltasks[25])/1000000000000000000 ;
          
 setLevelw(wlevel);
 const wlevel1 = Number(alltasks[19])/1000000000000000000;
          
 setLevelw1(wlevel1);

 

    // document.querySelector("#wi1").value = roi2;
     
    // const a1 = Number(alltasks1[0])-1;
          
    // sea1(a1);
    // const a3 = Number(alltasks1[2])/1000000000000000000;
          
   //  sea3(a3);
     const a2 = Number(alltasks[24]);
    sea2(a2);   
     var rr = 0;
    // const tt4 =  Number(alltasks[15])/1000000000000000000;
    // settt4(tt4);
     
     const tt =  Number(alltasks[20])/1000000000000000000;
     settt(tt);
     const tt3 = Number(alltasks[10])/1000000000000000000;
     settt3(tt3);
     document.querySelector("#bal").value = tt3;
     const referlink = 'https://winningwalks.com/?ref='+ account;
     setreflink(referlink);
     document.querySelector("#link").value =referlink;


     const upd = b1+b2+b3+b4+b5+b6+b7+b8+b9+b10+b11+b12+b13+b14+b15;
          
     setupd(upd);


console.log(pack);

const pack1 = 200;
setpackage1(pack1);

document.querySelector("#w1").value  = Number(alltasks[0]);
document.querySelector("#w2").value  = (alltasks[1]);
document.querySelector("#w3").value  = Number(alltasks[2]);
document.querySelector("#w4").value  = Number(alltasks[3]);
document.querySelector("#w5").value  = Number(alltasks[4]);
document.querySelector("#w6").value  = Number(alltasks[5]);
document.querySelector("#w7").value  = Number(alltasks[6]);
document.querySelector("#w8").value  = Number(alltasks[7]);
document.querySelector("#w9").value  = Number(alltasks[8]);
document.querySelector("#w10").value  = Number(alltasks[9]);
document.querySelector("#w11").value  = Number(alltasks[10]);
document.querySelector("#w12").value  = Number(alltasks[11]);
document.querySelector("#w13").value  = Number(alltasks[12]);
document.querySelector("#w14").value  = Number(alltasks[13]);
document.querySelector("#w15").value  = Number(alltasks[14]);
document.querySelector("#w16").value  = Number(alltasks[15]);
document.querySelector("#w17").value  = Number(alltasks[16]);
document.querySelector("#w18").value  = (alltasks[17]);
document.querySelector("#w19").value  = Number(alltasks[18]);
document.querySelector("#w20").value  = Number(alltasks[19]);
document.querySelector("#w21").value  = Number(alltasks[20]);
document.querySelector("#w22").value  = Number(alltasks[21]);
document.querySelector("#w23").value  = Number(alltasks[22]);
document.querySelector("#w24").value  = Number(alltasks[23]);
document.querySelector("#w25").value  = Number(alltasks[24]);
document.querySelector("#w26").value  = Number(alltasks[25]);
document.querySelector("#w27").value  = Number(alltasks[26]);

 
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
   
    
    connectWallet();
    
    function myFunction() {
      
      var copyText = "hello";
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      
    
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.valueOf);
    }

  }, []);

 
  // console.log(state);
  return (
  <div>
    <input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>
    <input type="hidden" id ="tt2"></input>
    <input type="hidden" id ="wi1"></input>
    <input type="hidden" id ="roi"></input>
    <input type="hidden" id ="bal"></input>

	<input type="hidden" id ="w1"></input>
	<input type="hidden" id ="w2"></input>
	<input type="hidden" id ="w3"></input>
	<input type="hidden" id ="w4"></input>
	<input type="hidden" id ="w5"></input>
	<input type="hidden" id ="w6"></input>
	<input type="hidden" id ="w7"></input>
	<input type="hidden" id ="w8"></input>
	<input type="hidden" id ="w9"></input>
	<input type="hidden" id ="w10"></input>
	<input type="hidden" id ="w11"></input>
	<input type="hidden" id ="w12"></input>
	<input type="hidden" id ="w13"></input>
	<input type="hidden" id ="w14"></input>
	<input type="hidden" id ="w15"></input>
	<input type="hidden" id ="w16"></input>
	<input type="hidden" id ="w17"></input>
	<input type="hidden" id ="w18"></input>
	<input type="hidden" id ="w19"></input>
	<input type="hidden" id ="w20"></input>
	<input type="hidden" id ="w21"></input>
	<input type="hidden" id ="w22"></input>
	<input type="hidden" id ="w23"></input>
	<input type="hidden" id ="w24"></input>
	<input type="hidden" id ="w25"></input>
	<input type="hidden" id ="w26"></input>
	<input type="hidden" id ="w27"></input>
   
    <div class="main-wrapper">

    <table><tr><td><img src="logo.png" width={"100px"} alt=""></img></td><td align="center"><h2>100% Decentralized Platform<br></br> Winning Walks</h2></td></tr></table>
  <marquee>Winning Walks is an innovative blockchain-based platform powered by a cutting-edge smart contract designed to provide exciting earning opportunities. As part of our vision to enhance financial freedom and community engagement, we are offering an exclusive airdrop of Winning Walks Token (WWT). By participating, users not only receive free tokens but also unlock pathways to generate substantial income through various rewarding mechanisms.

With transparency, security, and user empowerment at its core, Winning Walks combines decentralized finance principles with gamified earning, making it accessible and lucrative for participants worldwide. Join the movement and take your first step towards financial growth with Winning Walks!</marquee>
<div class="page-wrapper">
			<div class="content">
				<div class="row">
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-widget w-100">
							<div class="dash-widgetimg" >
								<span><img src="logo.png" alt="img"></img></span>
							</div>


							<div class="dash-widgetcontent">
								<h5><span class="counters" data-count={pack}>{pack} USDT</span></h5>
								<h6>My Purchase</h6>
							</div>
						</div>
					</div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-widget dash1 w-100">
							<div class="dash-widgetimg">
								<span><img src="logo.png" alt="img"></img></span>
							</div>
							<div class="dash-widgetcontent">
								<h5><span class="counters" data-count={pack1}>{pack1} WWT</span></h5>
								<h6>Token Allocated </h6>
							</div>
						</div>
					</div>

          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-widget dash2 w-100">
							<div class="dash-widgetimg">
								<span><img src="logo.png" alt="img"></img></span>
							</div>
							<div class="dash-widgetcontent">
								<h5><span class="counters" data-count= {direct}> {direct}</span></h5>
								<h6>Direct Team</h6>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-widget dash3 w-100">
							<div class="dash-widgetimg">
								<span><img src="logo.png" alt="img"></img></span>
							</div>
							<div class="dash-widgetcontent">
								<h5><span class="counters" data-count={level}>{level}</span></h5>
								<h6>Level Team</h6>
							</div>
						</div>
					</div>

          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-widget dash3 w-100">
							<div class="dash-widgetimg">
								<span><img src="logo.png" alt="img"></img></span>
							</div>
							<div class="dash-widgetcontent">
								<h5><span class="counters" data-count={level + direct}>{level + direct}</span></h5>
								<h6>Total Team</h6>
							</div>
						</div>
					</div>

          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count">
							<div class="dash-counts">
								<h4>  
                 {x1}</h4>
								<h5>Personal Growth Income</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das1">
							<div class="dash-counts">
								<h4>{x2}</h4>
								<h5>Direct Bonus</h5>
							</div>
							<div class="dash-imgs">
              <img src="logo.png"></img>
							</div>
						</div>
					</div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das2">
							<div class="dash-counts">
								<h4>{x3}</h4>
								<h5>Level Bonus</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png" class="img-fluid" alt="icon"></img>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das3">
							<div class="dash-counts">
								<h4>{reward}</h4>
								<h5>Bronze Leadership Bonus</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count">
							<div class="dash-counts">
              <h4>{reward1}</h4>
              <h5>Silver Leadership Bonus</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das1">
							<div class="dash-counts">
              <h4>{reward2}</h4>
              <h5>Gold Leadership Bonus</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das2">
							<div class="dash-counts">
              <h4>{reward3}</h4>
              <h5>Diamond Leadership Bonus</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png" class="img-fluid" alt="icon"></img>
							</div>
						</div>
					</div>
    


					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das3">
							<div class="dash-counts">
								<h4>{tt} USDT</h4>
								<h5>TOTAL Income</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>

          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count">
							<div class="dash-counts">
								<h4>{wlevel1} USDT</h4>
								<h5>TOTAL WITHDRAWL</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das1">
							<div class="dash-counts">
								<h4>{tt - wlevel1} USDT</h4>
								<h5>PENDING INCOME</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das2">
							<div class="dash-counts">
								<h4><Buy state={state}  /></h4>
								
							</div>
							<div class="dash-imgs">
								<img src="logo.png" class="img-fluid" alt="icon"></img>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das3">
							<div class="dash-counts">
								<h4><input type="text" id ="test"></input></h4>
								<h5><Buy3 state={state}  /></h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count">
							<div class="dash-counts">
								<h4>{b1}</h4>
								<h5>Level 1 Team</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das1">
							<div class="dash-counts">
              <h4>{b2}</h4>
              <h5>Level 2 Team</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das2">
							<div class="dash-counts">
              <h4>{b3}</h4>
              <h5>Level 3 Team</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png" class="img-fluid" alt="icon"></img>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das3">
							<div class="dash-counts">
              <h4>{b4}</h4>
              <h5>Level 4 Team</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count">
							<div class="dash-counts">
								<h4>{b5}</h4>
								<h5>Level 5 Team</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das1">
							<div class="dash-counts">
              <h4>{b6}</h4>
              <h5>Level 6 Team</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das2">
							<div class="dash-counts">
              <h4>{b7}</h4>
              <h5>Level 7 Team</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png" class="img-fluid" alt="icon"></img>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das3">
							<div class="dash-counts">
              <h4>{b8}</h4>
              <h5>Level 8 Team</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count">
							<div class="dash-counts">
								<h4>{b9}</h4>
								<h5>Level 9 Team</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das1">
							<div class="dash-counts">
              <h4>{b10}</h4>
              <h5>Level 10 Team</h5>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das2">
							<div class="dash-counts">
              <h5>{account}</h5>
              <h4>My Account</h4>
							</div>
							<div class="dash-imgs">
								<img src="logo.png" class="img-fluid" alt="icon"></img>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-count das3">
							<div class="dash-counts">
              <h5>{refer}</h5>
              <h4>My Refer</h4>
							</div>
							<div class="dash-imgs">
								<img src="logo.png"></img>
							</div>
						</div>
					</div>


         
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
						<div class="dash-widget dash1 w-100">
							
							<div class="dash-widgetcontent">
								<h6>{referlink}<a href="#">
       <button><img src ="copy.png" width={"15px"} onClick={() => {
         navigator.clipboard.writeText(referlink);}} /></button>
</a></h6>
								<h5>REFER LINK</h5>
							</div>
						</div>
					</div>
    
     

<h3>Join the Winning Walks Token Community and be part of something big!
</h3>
 <br></br> 
</div>
</div>
</div></div>







    <footer>
      <div class="container">
      <div class="row align-items-center">
              <div class="col-md-6">
          <p class="copyright">© 2025 All rights reserved <span>Winning Walks</span></p>
        </div>

      </div>
  
  
    </div>
    </footer>
  


<br></br>
<br></br>
</div>  
  );
}

export default App;